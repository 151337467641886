<template>
   <div>
	  <b-row>
		 <b-col md="4">
			<b-card :title="$t('pages.my-subscription')"
					class="border-primary">
			   <p>{{ $t("pages.subscription-p1") }}</p>
			   <p>{{ $t("pages.subscription-p2") }}</p>

			   <!-- media -->
			   <b-media class="mb-1"
						no-body>
				  <b-media-aside class="mr-1">
					 <b-avatar rounded
							   size="34"
							   variant="light-primary">
						<feather-icon icon="UserPlusIcon"
									  size="18" />
					 </b-avatar>
				  </b-media-aside>
				  <b-media-body>
                      <h6 class="mb-0">{{ registered_at }}</h6>
					 <small>{{ $t("pages.register") }}</small>
				  </b-media-body>
			   </b-media>

			   <b-media class="mb-1"
						no-body>
				  <b-media-aside class="mr-1">
					 <b-avatar rounded
							   size="34"
							   variant="light-danger">
						<feather-icon icon="InfoIcon"
									  size="18" />
					 </b-avatar>
				  </b-media-aside>
				  <b-media-body>
                      <h6 class="mb-0">{{ activeTill }}</h6>
					 <small>{{ $t("pages.subscription-end") }}</small>
				  </b-media-body>
			   </b-media>

			   <b-button
				   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				   block
				   class="mt-2"
				   href="https://beezio.app/login"
				   variant="primary"
			   >
				  Ανανέωση συνδρομής
			   </b-button>
			</b-card>
		 </b-col>
	  </b-row>
   </div>
</template>

<script>
import {
   BCard,
   BRow,
   BCol,
   BAvatar,
   BImg,
   BButton,
   BCardBody,
   BCardHeader,
   BMedia,
} from "bootstrap-vue";
import moment from "moment";

export default {
    data() {
        return {
            registered_at:'',
            activeTill:'',
		}
    },
    components: {
        BCard,
        BRow,
        BCol,
        BAvatar,
        BImg,
        BButton,
        BCardBody,
        BCardHeader,
        BMedia,
    },
    name: "Subscription",
    async created() {
        await this.$store.dispatch("fetchSubscription");
        this.registered_at = moment(this.$store.getters.getSubscription.registered_at).format('DD-MM-YYYY HH:mm');
        this.activeTill = moment(this.$store.getters.getSubscription.activeTill).format('DD-MM-YYYY HH:mm');
    }
};

</script>

<style scoped>
</style>